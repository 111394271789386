<template>
  <div>

    <div
        ref="formEmployeeContracts"
        class="repeater-form mt-2"
        :style="{height: trHeight}"
        :key="componentKey"
    >
      <div
          v-for="(employeeContract, index) in contracts"
          :key="index"
          ref="row"
          class="pb-2"
      >
        <employee-contract
            :employeeContract="employeeContract"
            :index="index"
            @duplicateEmployeeContract="duplicateEmployeeContract(index)"
            @removeEmployeeContract="removeEmployeeContract(index)"
        />

      </div>
    </div>

    <button-add
        size="sm"
        @click="addNewEmployeeContract"
        :text="$t('AddEmployeeContract')"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { heightTransition } from '@/utils/form'

import useAPI from '@/utils/useAPI'
import Ripple from 'vue-ripple-directive'
import EmployeeContract from './components/_employeeContract'
import store from '@/store'
import ButtonAdd from '@/components/button/Add'

export default {
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    EmployeeContract,
    ButtonAdd
  },
  props: {
    employeeContracts: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const currentBreakPoint = computed(() => {
      return store.getters['app/currentBreakPoint']
    })

    const contracts = computed(() => {
      return props.employeeContracts.sort((a, b) => {
        if (a.end != null && b.end != null) {
          return (a.end > b.end) ? -1 : ((b.end > a.end) ? 1 : 0)
        } else {
          return 1
        }
      })
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchEmployeeContactTypes } = useAPI()

    // const duplicateEmployeeContract = (index) => {
    //   let duplicatedEmployeeContract = JSON.parse(JSON.stringify(props.employeeContracts[index]))
    //   delete duplicatedEmployeeContract.id
    //
    //   // props.employeeContracts.unshift(duplicatedEmployeeContract)
    //   props.employeeContracts.splice(index, 0, duplicatedEmployeeContract)
    //   componentKey.value++
    // }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchEmployeeContactTypes()

    return {
      // Components

      // Data
      componentKey,

      // Computed
      currentBreakPoint,
      contracts,

      // Methods
      // duplicateEmployeeContract,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    addNewEmployeeContract () {
      this.employeeContracts.push({
        fte: 100
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    duplicateEmployeeContract(index) {
      let duplicatedEmployeeContract = JSON.parse(JSON.stringify(this.employeeContracts[index]))
      delete duplicatedEmployeeContract.id
      delete duplicatedEmployeeContract.files

      this.employeeContracts.push(duplicatedEmployeeContract)
      this.componentKey++

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeEmployeeContract (index) {
      this.employeeContracts.splice(index, 1)
      this.componentKey++

      this.$nextTick(() => {
        if (typeof this.$refs.row[0] !== 'undefined') {
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
        } else {
          this.initTrHeight()
        }
      })
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formEmployeeContracts.scrollHeight)
      })
    },
  },
  mounted () {
    this.initTrHeight()

    if (this.employeeContracts.length) {
      this.$nextTick(() => {
        if (['md', 'lg', 'xl'].includes(this.currentBreakPoint)) {
          this.trSetHeight(this.employeeContracts.length * 326.8)
        } else {
          this.trSetHeight(this.employeeContracts.length * 578)
        }
      })
    }
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>

<style lang="scss"
       scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>