<template>
  <div>
    <b-card
        no-body
        class="border mt-0"
    >
      <b-card-header class="pb-1">
        <!--        <b-card-title class="font-medium-2">-->
        <!--          <icon icon="file-contract"/>-->
        <!--          <span class="align-middle ml-50">{{ capitalize($tc('contract')) }}</span>-->
        <!--        </b-card-title>-->

        <b-card-title class="w-100 font-medium-2 d-flex justify-content-between">
          <div>
            <icon icon="file-contract"/>
            <span class="align-middle ml-50">{{ capitalize($tc('contract')) }}</span>
          </div>

          <div>

            <button-duplicate
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                @click.native.stop="$emit('duplicateEmployeeContract')"
                class="mt-0"
            />

            <button-remove
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                @click.native.stop="$emit('removeEmployeeContract')"
                class="mt-0"
            />
          </div>

        </b-card-title>


      </b-card-header>

      <b-row class="px-1">

        <!--      Type-->
        <b-col md="3">
          <field-select
              :model.sync="employeeContract.type"
              :name="'type'"
              :options="employeeContractTypes"
              :label="'name'"
              :reduce="'id'"
              :isRequired="true"
              :listIndex="index"
          />
        </b-col>

        <!--      Gross salary-->
        <b-col md="3">
          <field-input
              :name="'grossSalary'"
              :rules="'required|decimal|min_value:0'"
              :model.sync="employeeContract.grossSalary"
              :listIndex="index"
          />
        </b-col>

        <!--      FTE-->
        <b-col md="3">
          <field-input
              :isGroup="true"
              :name="'fte'"
              :rules="'integer|min_value:0'"
              :model.sync="employeeContract.fte"
              :listIndex="index"
          >
            <template #prepend>
              <icon icon="percent"/>
            </template>
          </field-input>
        </b-col>

        <!--      isProjected-->
        <b-col md="3">
          <field-switch
              :model.sync="employeeContract.isProjected"
              :name="$t('projected')"
              :listIndex="index"
          />
        </b-col>

        <b-col md="6">
          <b-row>

            <!-- Function -->
            <b-col md="12">
              <field-input
                  :name="'function'"
                  :rules="'required'"
                  :model.sync="employeeContract.function"
                  :listIndex="index"
              />
            </b-col>

            <!--          Start-->
            <b-col md="6">
              <field-date
                  :name="'start'"
                  :rules="'required'"
                  :model.sync="employeeContract.start"
                  :isRequired="true"
                  :listIndex="index"
              />
            </b-col>

            <!--          End-->
            <b-col md="6">
              <field-date
                  :name="'end'"
                  :model.sync="employeeContract.end"
                  :isRequired="!isCDIType"
                  :listIndex="index"
              />
            </b-col>

          </b-row>
        </b-col>

        <!--      Documents-->
        <b-col md="6">
          <b-card
              no-body
          >
            <b-card-header class="pb-1 cursor-pointer"
                           @click="uploadModalShow=true">
              <b-card-title class="w-100 font-medium-2 d-flex justify-content-between">
                <div>
                  <icon icon="file-contract"/>
                  <span class="align-middle ml-50">{{ capitalize($tc('file', 2)) }}</span>
                </div>

                <div>
                  <icon icon="upload"/>
                </div>

              </b-card-title>
            </b-card-header>

            <b-card-body>

              <document-content
                  v-for="(document, index) in employeeContract.files"
                  :document="document"
                  :isDeletable="true"
                  @delete="deleteEmployeeContractFile(index)"
                  :key="componentKey"
              />
              <!--              <card-document-->
              <!--                  v-for="(document, index) in employeeContract.files"-->
              <!--                  :extension="document.extension"-->
              <!--                  :name="document.name"-->
              <!--                  @click="download(document)"-->
              <!--                  @delete="deleteEmployeeContractFile(index)"-->
              <!--              />-->

            </b-card-body>
          </b-card>
        </b-col>

      </b-row>
    </b-card>


    <!-- modal outgoing invoice by upload-->
    <modal-upload
        :singleFile.sync="newFile"
        :documentType="'employeeContract'"
        :title="capitalize($tc('file'))"
        :isOpen.sync="uploadModalShow"
        :key="componentKey"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { isObject } from '../../../../utils/utils'

import useAPI from '@/utils/useAPI'
import FieldInput from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import FieldDate from '@/components/input/Date'
import FieldSwitch from '@/components/input/Switch'
// import DocumentContent from '@/components/card/components/DocumentContent'
import DocumentContent from '../../../../components/card/components/DocumentContent'
import ModalUpload from '@/components/prompt/Upload'
import ButtonDuplicate from '../../../button/Duplicate'
import ButtonRemove from '../../../button/Remove'

export default {
  components: {
    FieldInput,
    FieldSelect,
    FieldDate,
    FieldSwitch,
    // CardDocument,
    DocumentContent,
    ModalUpload,
    ButtonDuplicate,
    ButtonRemove
  },
  props: {
    employeeContract: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const uploadModalShow = ref(false)
    const newFile = ref(null)
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { employeeContractTypes } = useAPI()

    const isCDIType = computed(() => {
      return (isObject(props.employeeContract.type) &&
              props.employeeContract.type.id == 3) ||
          props.employeeContract.type == 3
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(newFile, (val) => {
      if (val != null) {
        if ('files' in props.employeeContract) {
          props.employeeContract.files.push(val)
        } else {
          props.employeeContract.files = [val]
        }

        uploadModalShow.value = false
        newFile.value = null
        componentKey.value++
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const download = (documentToDownload) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = documentToDownload.url
      link.click()
    }

    const deleteEmployeeContractFile = (employeeContractFileIndex) => {
      props.employeeContract.files.splice(employeeContractFileIndex, 1)
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      uploadModalShow,
      newFile,
      componentKey,

      // Computed
      employeeContractTypes,
      isCDIType,

      // Methods
      download,
      deleteEmployeeContractFile,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>