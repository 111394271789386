<template>
  <b-row>

    <!--      Account-->
    <b-col md="4">
      <b-row>

        <b-col cols="12 mb-1">
          <div class="d-flex">
            <icon
                icon="user"
                size="2x"
            />
            <h4 class="mb-0 ml-1 my-auto">
              {{ capitalize($tc('employee')) }}
            </h4>
          </div>
        </b-col>

        <!--          Avatar-->
        <b-col
            cols="12"
            class="mb-1"
        >
          <field-file
              :singleFile.sync="employee.avatar"
              :documentType="'avatar'"
          />
        </b-col>

        <!--          Email-->
        <b-col cols="12">
          <field-input
              :name="'email'"
              :rules="'required|email'"
              :model.sync="employee.email"
          />
        </b-col>

        <!--          Social security number-->
        <b-col cols="12">
          <social-security-number
              :model.sync="employee.socialSecurityNumber"
              :is-required="true"
          />
        </b-col>

        <!--          Rôles-->
        <b-col
            v-if="$can('manage', 'all')"
            cols="12"
        >
          <field-select
              :model.sync="employee.roles"
              :multiple="true"
              :name="'role'"
              :options="roleOptions"
              :reduce="'value'"
              :isRequired="true"
          />
        </b-col>

      </b-row>
    </b-col>

    <b-col md="8">
      <!--      Informations-->
      <b-row>

        <b-col cols="12 mb-1">
          <div class="d-flex">
            <icon
                icon="info-circle"
                size="2x"
            />
            <h4 class="mb-0 ml-1 my-auto">
              {{ $t('Informations') }}
            </h4>
          </div>
        </b-col>

        <!--          First name-->
        <b-col md="4">
          <field-input
              :name="'firstName'"
              :rules="'required'"
              :model.sync="employee.firstName"
          />
        </b-col>

        <!--          Name-->
        <b-col md="4">
          <field-input
              :name="'name'"
              :rules="'required'"
              :model.sync="employee.name"
          />
        </b-col>

        <!--          Phone-->
        <b-col md="4">
          <field-input
              :name="'phone'"
              :rules="'phone'"
              :model.sync="employee.phone"
          />
        </b-col>

        <!--          Date of birth-->
        <b-col md="4">
          <field-date
              :name="'dateOfBirth'"
              :model.sync="employee.dateOfBirth"
          />
        </b-col>
        <!--          Place of birth-->
        <b-col md="4">
          <field-address-by-query
              :name="'placeOfBirth'"
              :model.sync="employee.placeOfBirth"
              :city-only="true"
              :displayComplement="false"
          />
        </b-col>

        <!--          HP-->
        <b-col md="4">
          <field-input
              :name="'horsePower'"
              :rules="'integer|min_value:0'"
              :model.sync="employee.horsePower"
          />
        </b-col>

      </b-row>


      <!--        Address-->
      <b-row class="mt-2">

        <b-col cols="12 mb-1">
          <div class="d-flex">
            <icon
                icon="map-marker-alt"
                size="2x"
            />
            <h4 class="mb-0 ml-1 my-auto">
              {{ capitalize($tc('address')) }}
            </h4>
          </div>
        </b-col>

        <b-col cols="12">
          <field-address-by-query
              :model.sync="addressLocal"
          />
          <!--          <form-address-->
          <!--              :address="addressLocal"-->
          <!--              :isSimpleAddress="true"-->
          <!--          />-->
        </b-col>


      </b-row>

      <b-row class="mt-2">
        <b-col cols="12 mb-1">
          <div class="d-flex">
            <icon
                icon="signature"
                size="2x"
            />
            <h4 class="mb-0 ml-1 my-auto">
              {{ capitalize($t('mailSignature')) }}
            </h4>
          </div>
        </b-col>

        <b-col cols="12">
<!--          <field-textarea-->
<!--              :model.sync="employee.mailSignature"-->
<!--          />-->

<!--          <c-k-editor-->
<!--              :text.sync="employee.mailSignature"-->
<!--          />-->

          <input-textarea
              :text.sync="employee.mailSignature"
            />

        </b-col>
      </b-row>

      <!--      <b-row class="mt-2">-->
      <!--        <b-col cols="12 mb-1">-->
      <!--          <div class="d-flex">-->
      <!--            <icon icon="link"-->
      <!--                  size="2x"/>-->
      <!--            <h4 class="mb-0 ml-1 my-auto">-->
      <!--              {{ capitalize($tc('connection', 2)) }}-->
      <!--            </h4>-->
      <!--          </div>-->
      <!--        </b-col>-->

      <!--        <b-col cols="12">-->
      <!--          <icon-->
      <!--              icon="microsoft"-->
      <!--              library="fab"-->
      <!--              size="2x"-->
      <!--              :badge="true"-->
      <!--              :badge-color="hasMsAccount?'success':'danger'"-->
      <!--              class="cursor-pointer"-->

      <!--              @click.native="hasMsAccount?msDisConnect():msConnect()"-->
      <!--          >-->
      <!--            <template #badge>-->
      <!--              <icon v-if="hasMsAccount"-->
      <!--                    icon="check"/>-->
      <!--              <icon v-else-->
      <!--                    icon="times"/>-->
      <!--            </template>-->
      <!--          </icon>-->

      <!--        </b-col>-->
      <!--      </b-row>-->

    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize }           from '@/utils/filter'

import FieldInput                from '@/components/input/Input'
import FieldSelect               from '@/components/input/Select'
import FieldFile                 from '@/components/input/File'
import FieldDate                 from '@/components/input/Date'
import FieldSocialSecurityNumber from '@/components/input/SocialSecurityNumber'
import FieldAddressByQuery       from '@/components/input/AddressAutosuggest'
import FormAddress               from '@/components/form/Address'
import FieldTextarea             from '../../../input/Textarea.vue'
import store                     from '@/store'
import i18n                      from '@/libs/i18n'
import SocialSecurityNumber      from '../../../input/SocialSecurityNumber'
import { useMS }                 from '../../../../utils/useMS'
import InputTextarea             from '../../../input/InputTextarea.vue'

export default {
  components: {
    InputTextarea,
    SocialSecurityNumber,
    FieldInput,
    FieldSelect,
    FieldFile,
    FieldDate,
    FieldTextarea,
    FieldSocialSecurityNumber,
    FieldAddressByQuery,
    FormAddress,
  },
  props: {
    employee: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const statusOptions = ref([
      { label: i18n.t('Active'), value: true },
      { label: i18n.t('Deactivated'), value: false }
    ])

    const addressLocal = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const roleOptions = computed(() => {
      return store.getters['user/getRoleOptions']
    })

    // const getMsAccount = computed(() => {
    //   return store.getters['auth/getMsAccount']
    // })

    const hasMsAccount = computed(() => {
      return localStorage.getItem('msAccessToken') && localStorage.getItem('msAccessToken') != null && localStorage.getItem('msAccessToken') != 'null'
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(addressLocal, (val) => {
      if (props.employee.addresses.length) {
        props.employee.addresses[0] = val
      } else {
        props.employee.addresses.push(val)
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { login, logout } = useMS()

    const msConnect = () => {
      login()
    }

    const msDisConnect = () => {
      logout()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (props.employee.addresses.length) {
      addressLocal.value = JSON.parse(JSON.stringify(props.employee.addresses[0]))
    }

    return {
      // Components
      capitalize,

      // Data
      statusOptions,
      addressLocal,

      // Computed
      roleOptions,
      // getMsAccount,
      hasMsAccount,

      // Methods
      msConnect,
      msDisConnect,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>