<template>
  <b-form-group
      :label="capitalize($t('socialSecurityNumber'))"
      label-for="socialSecurityNumber"
  >
    <validation-provider
        #default="{ errors }"
        :name="$t('socialSecurityNumber')"
        :rules="(isRequired?'required|':'')+'socialSecurityNumber'"
    >
      <cleave
          id="socialSecurityNumber"
          v-model="localModel"
          class="form-control"
          :raw="false"
          :options="options"
          placeholder="0 00 00 00 000 000 00"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

  </b-form-group>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { ValidationProvider } from 'vee-validate'
import { required, socialSecurityNumber } from '../../utils/validations/validations'

import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const options = ref({
      numericOnly: true,
      blocks: [1, 2, 2, 2, 3, 3, 2]
    })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      required,
      socialSecurityNumber,

      // Data
      localModel,
      options,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    model: function (val) {
      this.localModel = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>